.PortfolioItem {
  text-align: center;
  margin: 0 auto;
  /* Following line causes floating elements to take up space in the div: */
  overflow: hidden;
  border-color: red;
  border-width: 3px;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  max-width: 1000px;
}

.PortfolioItem-Desc {
  float: left;
  text-align: left;
  width: 53.3%;
}

.PortfolioItem-Image {
  float: right;
  width: 45%;
}

.PortfolioImage {
  max-height: 25rem;
  max-width: 100%;
}

@media screen and (min-width: 1001px) {
  .PortfolioItem-Desc {
    padding-left: 0.8rem;
    /* This is to balance the white space to the edge of the image beside this text section */
  }
}

@media screen and (max-width: 1000px) {
  .PortfolioItem-Desc {
    width: 100%;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
  }

  .PortfolioItem-Image {
    width: 100%;
  }

  .PortfolioItem {
    max-width: 800px;
  }
}
