.App {
  text-align: center;
  padding: 10px;
}

.Text-SubTitle {
  font-family: graphik,-apple-system,helvetica,futura,sans-serif;
  font-size: 2.5rem;
  font-weight: 100;
  color: #313639;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.Text-Paragraph {
  font-family: graphik,-apple-system,helvetica,futura,sans-serif;
  font-size: 1rem;
  line-height: 1.33rem;
  font-weight: 300;
  color: #313639;
}

.Text-Paragraph-Link {
  font-family: graphik,-apple-system,helvetica,futura,sans-serif;
  font-size: 1rem;
  line-height: 1.33rem;
  font-weight: 300;
  color: #313639;
  text-decoration: underline;
  /* border-style: solid;
  border-width: 1px;
  padding: 2px; */
  margin-right: 0.5rem;
}

.Text-Paragraph-Link-NoEmphasis {
  font-family: graphik,-apple-system,helvetica,futura,sans-serif;
  font-size: 1rem;
  line-height: 1.33rem;
  font-weight: 300;
  color: #313639;
  text-decoration: none;
}

.Text-Paragraph-Link-NoEmphasis:hover {
  text-decoration: underline;
}